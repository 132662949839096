import React from "react"
import PropTypes from "prop-types"
import {Col, Container, Row} from "react-bootstrap";
import {isMobile, deviceType} from 'react-device-detect';

import {Document, Page} from 'react-pdf/dist/umd/entry.webpack';



class PdfViewer extends React.Component {

    render () {
    return (
        <React.Fragment>
            <Container style={{
                margin: isMobile ? '0' : 'auto',
                padding: isMobile ? '0' : '0 15 0 15'
            }}>
                <Row style={{margin: isMobile ? '0' : '0 15 0 15'}}>
                    <Col style={{padding: isMobile ? '0' : '0 15 0 15'}}>
                        <Document
                            file="https://s3.eu-west-3.amazonaws.com/cdn.mapresselocale.fr/r9f5gm8ty527jlh9cbg3nu3bpyem"
                            >
                            <Page renderAnnotationLayer={false}
                                  renderTextLayer={false}
                                  />
                        </Document>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
  }
}

PdfViewer.propTypes = {
  url: PropTypes.string
};
export default PdfViewer
