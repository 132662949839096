// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "trix";
import "@rails/actiontext";

import "@fortawesome/fontawesome-free/js/all";

import { addEdition } from "./addEdition";
import { addProJournal } from "./addProJournal";
import { addManagerJournal } from "./addManagerJournal";
import { selectJournal } from "./selectJournal";
import { selectUserType } from "./selectUserType";
import { removeVisuel } from "./removeVisuel";
import { couponEvent } from "./couponEvent";
import { checkboxBilling } from "./checkbox_billing";
import { bannerSlider } from "./bannerSlider";
import { slider } from "./slider";
import { menuDropdown } from "./menuDropdown";

// import { refreshCart } from "./refreshCart";
import "../stylesheets/application";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("slick-carousel");
global.toastr = require("toastr");

require("@nathanvda/cocoon");

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { menue } from "./menu";
import { homeSlick } from "./home-slick";
import { userSlick } from "./userSlick";

document.addEventListener("turbolinks:load", () => {
  if (document.querySelector("#fieldsetContainer")) {
    addEdition();
  }
  if (document.querySelector("#fieldsetProJournalContainer")) {
    addProJournal();
  }
  if (document.querySelector("#fieldsetManagerJournalsContainer")) {
    addManagerJournal();
  }
  if (document.querySelector("#journal_journal_id_select")) {
    selectJournal();
  }

  if (document.querySelector("#Type_account_names")) {
    selectUserType();
  }

  if (document.getElementById("remove_visuel")) {
    removeVisuel();
  }

  if (document.getElementById("coupon_duration")) {
    couponEvent();
  }

  if (document.getElementById("not_same")) {
    checkboxBilling();
  }

  if (document.getElementById("slider")) {
    slider();
  }

  if (document.getElementById("menu-toggle")) {
    menue();
  }

  if (document.getElementById("home-slick")) {
    homeSlick();
  }
  if (document.getElementById("bannerSlider")) {
    bannerSlider();
  }
  if (document.getElementById("userSlick")) {
    userSlick();
  }

  if (document.querySelectorAll("button.dropdown")) {
    menuDropdown();
  }
});
// $(document).on('load',function(){
//     $('#product-options').on('show', function(){
//         console.log("hello")
//         refreshCart()
//     })
// })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
