const selectJournal = () => {
    const selectField = document.getElementById("journal_journal_id_select")

    if (selectField !== null) {
        selectField.addEventListener('change', () => {
            let journalId = selectField.value
            window.location = `/reader/${journalId}/index`
        })
    }


}

export {selectJournal}