const menuDropdown = () => {
    // const settings = document.querySelector("#")
    let allMenuDropdown = document.querySelectorAll('button.dropdown')

    for (var i = 0; i < allMenuDropdown.length; i++) {
        allMenuDropdown[i].addEventListener('click', function(event) {
            // reset 
            for (var j = 0; j < allMenuDropdown.length; j++) {
                let MenuDropdownParent = allMenuDropdown[j].parentNode;
                if(!MenuDropdownParent.querySelector('div.hidden')) {
                    MenuDropdownParent.querySelector('div.absolute').classList.toggle("hidden");
                }
            }
            var parent = event.currentTarget.parentNode;
            parent.querySelector('div.absolute').classList.toggle("hidden");
        });
    }
}


export {menuDropdown}