const addEdition = () => {
    const createButton = document.getElementById("addEdition")

    createButton.addEventListener('click', () => {
        let lastId = document.querySelector('#fieldsetContainer').lastElementChild.id

        if(isNaN(lastId)){
            lastId = document.querySelector('#fieldsetContainer').getElementsByTagName('fieldset').length - 1
        }

        console.log(lastId)

        const newId = parseInt(lastId, 10) + 1

        const newFieldset = document.querySelector('[id="0"]').outerHTML.replace(/0/g, newId.toString(10))

        document.querySelector('#fieldsetContainer').insertAdjacentHTML(
            'beforeend', newFieldset
        )

        if(document.getElementById(`journal_editions_attributes_${newId}_name`) !== null){
            document.getElementById(`journal_editions_attributes_${newId}_name`).labels[0].innerHTML = 'Nom de l\'édition'
        }

        if(document.getElementById(`journal_editions_attributes_${newId}_name`) !== null){
            document.getElementById(`journal_editions_attributes_${newId}_name`).value = ''
        }

        if(document.getElementById(`journal_editions_attributes_${newId}_logo_edition_attributes_id`) !== null){
            document.getElementById(`journal_editions_attributes_${newId}_logo_edition_attributes_id`).remove()
        }

        const newFieldsetInsered = document.querySelector(`[id="${newId.toString(10)}"]`)

        console.log(newFieldsetInsered)

        if( newFieldsetInsered.getElementsByTagName('img')[0] !== undefined){
            newFieldsetInsered.getElementsByTagName('img')[0].remove()
        }


    })
}

export {addEdition}