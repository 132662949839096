const selectUserType = () => {

    // récupérer les select et les boutons
    const exportUserBtn = document.querySelector("a.exportUserBtn");
    let paramsBtn = '';
    let url = exportUserBtn.getAttribute('href');
    const Type_account_names = document.getElementById("Type_account_names");
    const Type_de_Compte_account_names = document.getElementById("Type_de_Compte_account_names");
    const Type_subscription = document.getElementById("Type_d_abonement_subscribe_type");
    // récupérer et formater l'url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const account_type = urlParams.get('account_type');
    const account_model = urlParams.get('account_model');
    const subscription = urlParams.get('subscribe_type');

    if(account_type != null || account_model != null || subscription != null) {  paramsBtn += '?'; }

    if(account_type != null) 
    { 
        Type_account_names.value = account_type; 
        paramsBtn += `account_type=${ account_type }` 
    }

    if(account_type != null && account_model != null) {  paramsBtn += '&'; }

    if(account_model != null) 
    { 
        Type_de_Compte_account_names.value = account_model; 
        paramsBtn += `account_model=${ account_model }` 
    }

    if((account_type != '' || account_model != '') && subscription != '' ) {  paramsBtn += '&'; }

    if(subscription != null) 
    { 
        Type_subscription.value = subscription; 
        paramsBtn += `subscribe_type=${ subscription }` 
    }


  if (Type_account_names !== null && Type_de_Compte_account_names !== null && Type_subscription !== null) {

    Type_account_names.addEventListener('change', () => {
            filterdata()
        })
        Type_de_Compte_account_names.addEventListener('change', () => {
            filterdata()
        })
        Type_subscription.addEventListener('change', () => {
            filterdata()
        })
    }

    exportUserBtn.href = "/export/users_csv.csv"+paramsBtn



    function filterdata() {
        let URL = '/users';
        let params = '';
	console.log("Filter data")
        if(Type_de_Compte_account_names.value != '' || Type_account_names.value != '' || Type_subscription.value != '') {
            params += '?';
        }

        if(Type_account_names.value != '') {
            params += `account_type=${ Type_account_names.value }`
        }
        if(Type_de_Compte_account_names.value != '' && Type_account_names.value != '') {
            params += '&';
        }
        if(Type_de_Compte_account_names.value != '') {
            params += `account_model=${ Type_de_Compte_account_names.value }`
        }

        if( (Type_de_Compte_account_names.value != '' || Type_account_names.value != '') && Type_subscription.value != '' ) {
            params += '&';
        }

        if(Type_subscription.value != '') {
            params += `subscribe_type=${ Type_subscription.value }`
        }

        window.location = URL + params
    }
}

export {selectUserType}
