const addProJournal = () => {

    const checkbox = document.getElementById("user_professional_information_attributes_full")

    const createButton = document.getElementById("addProJournal")
    const stateMessage = document.getElementById("state")

    if(checkbox.checked){
        document.getElementById("fieldsetProJournalContainer").style.display = 'none'
	stateMessage.innerText = "Cet utilisateur a accès à tous les titres."
    }else{
      document.getElementById("expiration_date").style.display = 'none'
	stateMessage.innerText = "Cet utilisateur a accès aux titres suivants :"
    }

    checkbox.addEventListener('change', () => {
        var inputs = document.querySelectorAll('#fieldsetProJournalContainer input[type="date"]');

        if(checkbox.checked){
            document.getElementById("fieldsetProJournalContainer").style.display = 'none'
            document.getElementById("expiration_date").style.display = 'block'
            stateMessage.innerText = "Cet utilisateur a accès à tous les titres." 
            for (var i = 0; i < inputs.length; i += 1) {
                inputs[i].removeAttribute("required");
            }
        }else{
            document.getElementById("fieldsetProJournalContainer").style.display = 'block'
            document.getElementById("expiration_date").style.display = 'none'
            stateMessage.innerText = "Cet utilisateur a accès aux titres suivant :"
            for (var i = 0; i < inputs.length; i += 1) {
                inputs[i].setAttribute("required", "");
            }
        }
    })


}

export {addProJournal}
