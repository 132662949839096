const removeVisuel = () => {
    const removeButton = document.getElementById("remove_visuel")

    removeButton.addEventListener('click', () => {
        let visuel = document.getElementById('visuel')

        visuel.style.display = 'none'
        removeButton.style.display = 'none'

        let action = document.getElementById('remove')
        action.value = 'true'
    })
}

export {removeVisuel}