const bannerSlider = ()=> {
  $('#bannerSlider').slick({
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: false,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      adaptiveHeight: false,
      lazyLoad:"ondemand"
    });

}
export {bannerSlider}