const slider = () => {
    $('#slider').slick({
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '60px',
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        responsive: [
        {
            breakpoint: 840,
            settings: {
                centerPadding: '0px'
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
    });
}

export {slider}