import React from "react"
import PropTypes from "prop-types"
import axios from "axios";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
class Search extends React.Component {
  state = {
    persons: null,
    loading: false,
    value: ''
  }

  search = async val => {
    this.setState({loading: true})
    const res = await axios(`/api/v1/search/person?query=${val}&api_key=sesf8793s57fesfsef87fesfes3s578dsf`)
    const persons = await res.data.results

    this.setState({persons, loading: false})
  }

  onChangeHandler = async e => {
    this.setState({value: e.target.value})
  }

  onHandleSeachButton() {
    const terms = this.state.value

    this.search(terms)
  }

  get terms(){
    return this.state.value
  }

  get renderPersons() {
    let persons = ''
    if (this.state.persons){
      persons = <h1>Test</h1>
    }

    return persons
  }

  render () {
    return (
      <React.Fragment>
          <div>
            <Container>
              <Row>
                <input type="text" value={this.state.value} onChange={e => this.onChangeHandler(e)} placeholder={'Email'}/>
                <Button variant="outline-primary" onClick={this.onHandleSeachButton.bind(this)}>Rechercher</Button>
              </Row>
            </Container>

            {this.renderPersons}
          </div>
      </React.Fragment>
    );
  }
}

export default Search
