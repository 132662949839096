const checkboxBilling = () => {

    const checkbox = document.getElementById("not_same")

    if(checkbox.checked){
        document.getElementById("billing_addresses").style.display = 'block'
    }else{
        document.getElementById("billing_addresses").style.display = 'none'
    }

    checkbox.addEventListener('change', () => {
        if(checkbox.checked){
            document.getElementById("billing_addresses").style.display = 'block'
        }else{
            document.getElementById("billing_addresses").style.display = 'none'
        }
    })
}

export {checkboxBilling}