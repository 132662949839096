const addManagerJournal = () => {

    const checkbox = document.getElementById("isFull")

    const createButton = document.getElementById("addManagerJournal")

    if(checkbox.checked){
        document.getElementById("fieldsetManagerJournalsContainer").style.display = 'none'
    }

    checkbox.addEventListener('change', () => {
        if(checkbox.checked){
            document.getElementById("fieldsetManagerJournalsContainer").style.display = 'none'
        }else{
            document.getElementById("fieldsetManagerJournalsContainer").style.display = 'block'
        }
    })


    if (createButton !== null) {
        createButton.addEventListener('click', () => {
            let lastId = document.querySelector('#fieldsetManagerJournalsContainer').lastElementChild.id

            if(isNaN(lastId)){
                lastId = document.querySelector('#fieldsetManagerJournalsContainer').getElementsByTagName('fieldset').length - 1
            }



            const newId = parseInt(lastId, 10) + 1

            const newFieldset = document.querySelector('[id="0"]').outerHTML.replace(/0/g, newId.toString(10))

            document.querySelector('#fieldsetManagerJournalsContainer').insertAdjacentHTML(
                'beforeend', newFieldset
            )
        })
    }


}

export {addManagerJournal}