const couponEvent = () => {
    const selectField = document.getElementById("coupon_duration")
    const durationField = document.getElementById("coupon_duration_in_months")
    const publicField = document.getElementById("coupon_readable")
    const multipleField = document.getElementById("multiple_form_coupon_multiple")
    if (selectField !== null) {
        if(durationField.value !== 'Plusieurs mois'){
            durationField.style.display = 'none'
        }

        if(!publicField.checked){
            multipleField.style.display = 'none'
        }

        selectField.addEventListener('change', () => {
            let value = selectField.value
                if(value === 'Plusieurs mois'){
                    durationField.style.display = 'block'
                }else{
                    durationField.style.display = 'none'
                }
        })

        publicField.addEventListener('change', () => {
            let value = publicField.checked
            if(value){
                multipleField.style.display = 'block'
            }else{
                multipleField.style.display = 'none'
            }
        })
    }


}

export {couponEvent}